import { DetailsPageVariant } from "./DetailsPageVariant"
import { SlimListingVariant } from "./SlimListingVariant"
import { VariantType } from "./VariantType"

export type DomainVariant = DetailsPageVariant | SlimListingVariant

export const isDetailsVariant: (
  variant: DomainVariant
) => variant is DetailsPageVariant = (
  variant: DomainVariant
): variant is DetailsPageVariant => {
  return variant.variantType === VariantType.Detail
}
