import { Currency } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"
import { Money } from "@commercetools/platform-sdk"
import { Option } from "funfix-core"
import { DetailsPageVariant, StockLevel } from "./models/DetailsPageVariant"
import { Price } from "./models/Price"
import { SimpleAsset } from "./models/SimpleAsset"
import { variantAvailabilityIs } from "./ProductDal"
import { TypedRecommendation } from "./Recommendations"
import {
  ColorTerm,
  GarmentComposition,
  getGarmentCompositionValue,
  KeyAndLabel,
  ModelDetail,
  nonEmpty,
  Size,
  Style,
  KeyAndValue
} from "./VariantProxy"
import { VariantProxyI, variantAsset } from "./VariantProxyI"

export function findPriceForCurrencyCode(
  currency: Currency,
  prices: Price[] = []
) {
  return Option.of(prices).flatMap(p =>
    Option.of(p.find(it => it.value.currencyCode === currency))
  )
}

export class DetailVariantProxy implements VariantProxyI {
  get isInStock(): boolean {
    return this.variant.availability !== "out-of-stock"
  }

  get stockLevel(): StockLevel {
    return this.variant.availability
  }

  private variant: DetailsPageVariant

  constructor(variant: DetailsPageVariant) {
    this.variant = variant
  }

  public detailDescription(language: Language): Option<string> {
    return Option.of(this.variant.detailsShortDescription).map(
      it => it[language]
    )
  }

  public colorCode(): Option<string> {
    return Option.of(this.variant.colorCode)
  }

  public colorHex(): Option<string> {
    return Option.of(this.variant.colorHexcode)
  }

  public colorName(language: Language): Option<string> {
    return Option.of(this.variant.colorName).flatMap(it =>
      Option.of(it[language])
    )
  }

  public colorTerm(): Option<ColorTerm> {
    return Option.of(this.variant.colorTerm)
  }

  public colorTermKey(): Option<string> {
    return this.colorTerm().map(it => it.key)
  }

  public colorTermName(language: Language): Option<string> {
    return this.colorTerm().flatMap(it => Option.of(it.label[language]))
  }

  public functionality(language: Language): KeyAndValue[] {
    return (this.variant.functionality || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public functionalityKey(): Option<string[]> {
    return Option.of((this.variant.functionality || []).map(it => it.key))
  }

  public functionalityObj(): Option<KeyAndLabel[]> {
    return Option.of(this.variant.functionality || [])
  }

  public phoneticSpelling(): string {
    if (
      this.variant.phoneticSpelling &&
      this.variant.phoneticSpelling.length > 0
    ) {
      return this.variant.phoneticSpelling[0] || ""
    }
    return ""
  }

  public garmentComposition(
    language: Language
  ): Array<{ name: string; value: string }> {
    const getCompositionName = (composition: GarmentComposition[]) => {
      const garment = getGarmentCompositionValue(
        "garment",
        composition,
        language
      ).join(" ")
      const fabric = getGarmentCompositionValue(
        "fabric",
        composition,
        language
      ).join(" ")
      return [garment, fabric].filter(nonEmpty).join(" - ")
    }
    const getCompositionValue = (composition: GarmentComposition[]) => {
      const layerComposition = getGarmentCompositionValue(
        "layer-composition",
        composition,
        language
      ).join(", ")
      const brandedSupplier = getGarmentCompositionValue(
        "branded-supplier",
        composition,
        language
      ).join(", ")
      const certificate = getGarmentCompositionValue(
        "tpd-certificates",
        composition,
        language
      ).join(", ")

      return [layerComposition, brandedSupplier, certificate]
        .filter(nonEmpty)
        .join(" | ")
    }

    return (this.variant.tdpGarmentComposition || []).map(comp => {
      return {
        name: getCompositionName(comp),
        value: getCompositionValue(comp)
      }
    })
  }

  public getAllProductRecommendations(
    language: Language
  ): TypedRecommendation[] {
    return this.variant.recommendations || []
  }
  public asset(): Option<SimpleAsset> {
    return variantAsset(this)
  }

  public imageUrl(): Option<string> {
    return this.asset().map(it => it.url)
  }

  public layering(language: Language): KeyAndValue[] {
    return (this.variant.layering || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public listingDescription(language: Language): Option<string> {
    return Option.of(this.variant.listingDescription).flatMap(it =>
      Option.of(it[language])
    )
  }
  public p(currency: Currency): Option<Price> {
    return findPriceForCurrencyCode(currency, this.variant.prices)
  }

  public price(currency: Currency): Option<Money> {
    return this.p(currency).map(it => it.value)
  }
  public discountedPrice(currency): Option<Money> {
    return this.p(currency).flatMap(it =>
      Option.of(it.discounted).map(it => it.value)
    )
  }

  public shells(language): KeyAndValue[] {
    return (this.variant.shells || []).map(it => {
      return {
        key: it.key,
        value: it.label[language]
      }
    })
  }

  public size(): Option<Size> {
    return Option.of(this.variant.size)
  }

  public sizeKey(): Option<string> {
    return this.size().map(it => it.key)
  }

  public sizeName(language): Option<string> {
    return this.size().flatMap(it => Option.of(it.label[language]))
  }

  public sku(): string {
    return this.variant.sku || ""
  }

  public style(): Option<Style> {
    return Option.of(this.variant.style)
  }

  public styleKey(): Option<string> {
    return this.style().map(it => it.key)
  }

  public styleName(language: Language): Option<KeyAndValue> {
    return this.style().flatMap(it => {
      return Option.of({
        key: it.key,
        value: it.label[language]
      })
    })
  }

  public suitableFor(language: Language): string[] {
    return (this.variant.suitableFor || []).map(it => it.label[language])
  }

  public tpdSuitableFor(language: Language): KeyAndValue[] {
    return (this.variant.tpdSuitableFor || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public tpdWashingInstructions(language: Language): KeyAndValue[] {
    return (this.variant.tpdWashingInstructions || []).map(it => {
      return {
        key: it.key,
        value: it.label[language]
      }
    })
  }

  public variantAvailabilityIs(it: DetailsPageVariant, availability: string) {
    return variantAvailabilityIs(it, availability)
  }

  public fitDescription(language: Language) {
    return Option.of(this.variant.fitDescription).flatMap(it =>
      Option.of(it[language])
    )
  }

  public modelDetails(): ModelDetail[] {
    return this.variant.modelDetail || []
  }

  public assets(): SimpleAsset[] {
    return this.variant.assets || []
  }
}
