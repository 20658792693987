import {
  Image,
  ProductVariant,
  ChannelReference
} from "@commercetools/platform-sdk"
import { LocalizedString } from "@commercetools/platform-sdk/dist/declarations/src/generated/models/common"
import { ProductVariantAvailability } from "@commercetools/platform-sdk/dist/declarations/src/generated/models/product"
import { Store } from "cart/Stores"
import { transformPricesForCurrency } from "utils/ProductUtils"
import { filterUndefinedKeys } from "../../utils/Filter"
import logger from "../../utils/logger"
import { TypedRecommendation, createRecommendations } from "../Recommendations"
import {
  attributeValue,
  attributeValues,
  extractModelDetail,
  GarmentComposition,
  KeyAndLabel,
  ModelDetail,
  Size
} from "../VariantProxy"
import { variantAssets } from "../VariantProxyI"
import { Price } from "./Price"
import { SimpleAsset } from "./SimpleAsset"
import { VariantType } from "./VariantType"

export interface DetailsPageVariant {
  readonly id: number
  readonly sku?: string
  readonly key?: string
  readonly prices?: Price[]
  readonly assets: SimpleAsset[]
  readonly availability: StockLevel
  readonly listingLabel?: LocalizedString
  readonly listingDescription?: LocalizedString
  readonly detailsShortDescription?: LocalizedString
  readonly colorTerm?: KeyAndLabel
  readonly colorName?: LocalizedString
  readonly colorCode?: string
  readonly colorHexcode?: string
  readonly phoneticSpelling?: string[]
  readonly fitDescription?: LocalizedString
  readonly style?: KeyAndLabel
  readonly styles?: KeyAndLabel[]
  readonly styleKey?: string
  readonly size?: Size
  readonly tdpGarmentComposition?: GarmentComposition[][]
  readonly tpdSuitableFor?: KeyAndLabel[]
  readonly shells?: KeyAndLabel[]
  readonly suitableFor?: KeyAndLabel[]
  readonly functionality?: KeyAndLabel[]
  readonly variantType: VariantType
  readonly variantAvailability?: KeyAndLabel
  readonly layering?: KeyAndLabel[]
  readonly tpdWashingInstructions?: KeyAndLabel[]
  readonly modelDetail?: ModelDetail[]
  readonly isMatchingVariant?: boolean
  readonly salesChannels?: ChannelReference[]
  readonly recommendations?: TypedRecommendation[]
}

export type StockLevel =
  | "in-stock"
  | "low-stock"
  | "two-left"
  | "one-left"
  | "out-of-stock"

export class DetailsPageVariants {
  public static fromProductVariant(variant: ProductVariant, store?: Store) {
    return filterUndefinedKeys({
      id: variant.id,
      sku: variant.sku || "",
      key: variant.key,
      prices: transformPricesForCurrency(variant.prices, store?.currency),
      assets: variantAssets(variant),
      availability: optimisedAvailability(variant.availability, variant.sku),
      fitDescription: attributeValue<LocalizedString>(
        "fitDescription",
        variant.attributes
      ),
      listingLabel: attributeValue<LocalizedString>(
        "listingLabel",
        variant.attributes
      ),
      listingDescription: attributeValue<LocalizedString>(
        "listingDescription",
        variant.attributes
      ),
      detailsShortDescription: attributeValue<LocalizedString>(
        "detailsShortDescription",
        variant.attributes
      ),
      colorTerm: attributeValue<KeyAndLabel>("colorTerm", variant.attributes),
      colorName: attributeValue<LocalizedString>(
        "colorName",
        variant.attributes
      ),
      colorCode: attributeValue<string>("colorCode", variant.attributes),
      colorHexcode: attributeValue<string>("colorHexcode", variant.attributes),
      size: attributeValue<Size>("size", variant.attributes),
      recommendations: createRecommendations(variant.attributes || [], store),
      phoneticSpelling: attributeValues<string>(
        "phoneticSpelling",
        variant.attributes
      ),
      shells: attributeValues<KeyAndLabel>("Shell", variant.attributes),
      layering: attributeValues<KeyAndLabel>("layering", variant.attributes),
      tpdSuitableFor: attributeValues<KeyAndLabel>(
        "tpd-suitable-for",
        variant.attributes
      ),
      tpdWashingInstructions: attributeValues<KeyAndLabel>(
        "tpd-washing-instructions",
        variant.attributes
      ),
      variantAvailability: attributeValue<KeyAndLabel>(
        "variant-availability",
        variant.attributes
      ),
      variantType: VariantType.Detail,
      functionality: attributeValues<KeyAndLabel>(
        "functionality",
        variant.attributes
      ),
      suitableFor: attributeValues<KeyAndLabel>(
        "suitableFor",
        variant.attributes
      ),
      style: attributeValue<KeyAndLabel>("style", variant.attributes),
      tdpGarmentComposition: attributeValues<GarmentComposition[]>(
        "tpd-garment-composition",
        variant.attributes
      ),
      modelDetail: [
        extractModelDetail("maleModel", variant.attributes || []),
        extractModelDetail("femaleModel", variant.attributes || [])
      ]
        .filter(it => it.nonEmpty())
        .map(it => it.get()),
      isMatchingVariant: variant.isMatchingVariant,
      salesChannels:
        attributeValues<{ typeId: "channel"; id: string }>(
          "sales-channel",
          variant.attributes
        )?.map(it => it as ChannelReference) || []
    })
  }
}

export const optimisedAvailability = (
  availability: ProductVariantAvailability | undefined,
  sku: string = ""
): StockLevel => {
  const availabilities = Object.values(availability?.channels || {})
  const isAvailable = availabilities.find(it => !!it.isOnStock) !== undefined
  const totalAvailable = availabilities.reduce(
    (previous: number, it) => (it.availableQuantity || 0) + previous,
    0
  )
  const stockLevel: StockLevel =
    totalAvailable === 0
      ? "out-of-stock"
      : totalAvailable === 1
        ? "one-left"
        : totalAvailable === 2
          ? "two-left"
          : totalAvailable < 4
            ? "low-stock"
            : "in-stock"
  if (
    (isAvailable && stockLevel === "out-of-stock") ||
    (!isAvailable && stockLevel !== "out-of-stock")
  ) {
    logger.warn(
      `Something weird is happening with stock levels for: ${sku} | ${isAvailable} | ${stockLevel}`
    )
  }

  return stockLevel
}

const MISSING_IMAGE = {
  label: "Image unavailable",
  url: "https://images.66north.com/assets/45042.png",
  dimensions: {
    h: 1232,
    w: 1232
  }
}

export const missingImageFallbackFor = (
  images: Image[] | undefined
): Image[] => {
  const result = images || []
  return result.length === 0 ? [MISSING_IMAGE] : result
}
