import { Currency } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"
import { Image, Money, ProductVariant } from "@commercetools/platform-sdk"
import { Option } from "funfix-core"
import {
  missingImageFallbackFor,
  StockLevel
} from "./models/DetailsPageVariant"
import { DomainVariant } from "./models/DomainVariant"
import { SimpleAsset } from "./models/SimpleAsset"
import { Recommendation, TypedRecommendation } from "./Recommendations"
import {
  ColorTerm,
  KeyAndLabel,
  ModelDetail,
  Size,
  Style,
  KeyAndValue
} from "./VariantProxy"

export interface VariantProxyI {
  isInStock: boolean
  stockLevel: StockLevel

  sku(): string

  detailDescription(language: Language): Option<string>

  listingDescription(language: Language): Option<string>

  colorName(language: Language): Option<string>

  colorTerm(): Option<ColorTerm>

  colorTermKey(): Option<string>

  colorTermName(language: Language): Option<string>

  colorHex(): Option<string>

  colorCode(): Option<string>

  phoneticSpelling(): string

  size(): Option<Size>

  getAllProductRecommendations(language: Language): TypedRecommendation[]

  garmentComposition(language: Language): { name: string; value: string }[]

  sizeKey(): Option<string>

  sizeName(language: Language): Option<string>

  price(currency: Currency): Option<Money>

  discountedPrice(currency: Currency): Option<Money>

  style(): Option<Style>

  styleKey(): Option<string>

  functionalityKey(): Option<string[]>

  styleName(language: Language): Option<KeyAndValue>

  asset(): Option<SimpleAsset>

  assets(): SimpleAsset[]

  shells(language: Language): KeyAndValue[]

  functionalityObj(): Option<KeyAndLabel[]>

  functionality(language: Language): KeyAndValue[]

  suitableFor(language: Language): string[]

  layering(language: Language): KeyAndValue[]

  tpdWashingInstructions(language: Language): KeyAndValue[]

  tpdSuitableFor(language: Language): KeyAndValue[]

  imageUrl(): Option<string>

  variantAvailabilityIs(variant: DomainVariant, availability: string): boolean

  modelDetails(): ModelDetail[]
}

export function toSimpleAsset(image: Image): SimpleAsset {
  return {
    tags: [],
    label: image.label,
    url: image.url
  }
}

export const variantAsset: (variant: VariantProxyI) => Option<SimpleAsset> =
  function (variant: VariantProxyI): Option<SimpleAsset> {
    return Option.of((variant.assets() || [])[0])
  }

export const variantAssets: (variant: ProductVariant) => SimpleAsset[] =
  function (variant: ProductVariant): SimpleAsset[] {
    const simpleAssets = (variant.assets || []).flatMap(asset =>
      asset.sources?.map(source => ({
        tags: asset.tags || [],
        url: source.uri,
        label: asset.name["en"] || ""
      }))
    )

    return simpleAssets.length > 0
      ? simpleAssets
      : missingImageFallbackFor(undefined).map(image => toSimpleAsset(image))
  }

export type SKU =
  `${Uppercase<string>}-${Uppercase<string>}-${Uppercase<string>}`

export const skuComponents: (sku?: SKU) => {
  product: string
  color: string
  size: string
} = sku => {
  const [product, color, size] = sku?.split("-") || []
  return { product, color, size }
}
