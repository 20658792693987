import React from "react"
import {
  PrismicDocument,
  PrismicLinkedDocument
} from "../prismic/PrismicModels"

export const NavigationContext = React.createContext<{
  navigation?: PrismicDocument
  navigationTrays?: PrismicDocument[]
  navigationLinkedDocuments?: PrismicLinkedDocument[]
}>({})
