import { DiscountedPrice, TypedMoney } from "@commercetools/platform-sdk"
import { isTouchDevice } from "@sixty-six-north/ui-system"
import { assetsTaggedFor } from "product/AssetsTaggedFor"
import { DomainCategory } from "product/models/DomainCategory"
import { ImageView } from "../category/ProductListing"
import { DomainProduct } from "../product/models/DomainProduct"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { Price } from "../product/models/Price"
import { SimpleAsset } from "../product/models/SimpleAsset"
import { VariantProxy } from "../product/VariantProxy"

const IMAGES_DOMAIN = "https://images.66north.com"

export const getProductImagesForView = (
  view: ImageView | undefined,
  matchingImages: SimpleAsset[]
) => {
  const modelImage = matchingImages.find(it => it.tags.includes("Model"))
  const productImage = matchingImages.find(it => it.tags.includes("Product"))
  const untaggedImages = matchingImages.filter(
    it => !it.tags.includes("Product") && !it.tags.includes("Model")
  )
  const firstUntaggedImage = untaggedImages[0]
  const firstImage: string = matchingImages[0]?.url || ""

  let imageUrl: string
  let hoverImageUrl: string

  switch (view) {
    case "PRODUCT":
      imageUrl =
        productImage?.url ||
        modelImage?.url ||
        firstUntaggedImage?.url ||
        firstImage
      hoverImageUrl =
        modelImage && imageUrl !== modelImage?.url
          ? modelImage?.url
          : firstUntaggedImage?.url || ""
      break
    case "MODEL":
    default:
      imageUrl =
        modelImage?.url ||
        productImage?.url ||
        firstUntaggedImage?.url ||
        firstImage
      hoverImageUrl =
        productImage && imageUrl !== productImage?.url
          ? productImage?.url
          : firstUntaggedImage?.url || ""
      break
  }

  if (imageUrl === hoverImageUrl)
    hoverImageUrl = untaggedImages.length > 1 ? untaggedImages[1]?.url : ""

  if (isTouchDevice()) {
    hoverImageUrl = ""
  }

  if (imageUrl && !imageUrl.startsWith("http")) {
    imageUrl = IMAGES_DOMAIN + imageUrl
  }
  if (hoverImageUrl && !hoverImageUrl.startsWith("http")) {
    hoverImageUrl = IMAGES_DOMAIN + hoverImageUrl
  }
  return {
    imageUrl,
    hoverImageUrl
  }
}

export const domainProductHasAnyStock = (product: DomainProduct) => {
  const productVariants = [product.masterVariant, ...product.variants]
  return !!productVariants.find(v => {
    const vProxy = new VariantProxy(v)
    return vProxy.isInStock
  })
}

export const hierarchicalProductHasAnyStock = (
  product: CoreProductInformation
) => {
  return Object.values(product.colorways).some(c => c.inStock)
}

const filterPricesForCurrency = (price: Price, currency: string = "EUR") => {
  return (
    price.value.currencyCode === currency || price.value.currencyCode === "EUR"
  )
}

export const transformPricesForCurrency = (
  prices?: Price[],
  currency?: string
): Price[] => {
  return (
    (prices || [])
      .filter(p => filterPricesForCurrency(p, currency))
      .map(p => {
        const price: { value: TypedMoney; discounted?: DiscountedPrice } = {
          value: p.value
        }
        if (p.discounted) price.discounted = p.discounted
        return price
      }) || []
  )
}

const removeDuplicatedTaggedAssets = (assets: SimpleAsset[] = []) => {
  const result: { tags: string[]; url: string }[] = []
  const tagCounter = {}
  assets.forEach(asset => {
    const tags = asset.tags.toString() || "no-tags"
    if (!tagCounter[tags]) {
      result.push({ tags: asset.tags, url: asset.url })
      tagCounter[tags] = 1
    }
  })
  if (result.length < 2 && assets.length >= 2) {
    const found = assets.find(a => !result.find(r => r.url === a.url))
    if (found) result.push(found)
  }
  return result
}

export const transformAssetsForVariant = (
  assets: SimpleAsset[] = [],
  parentCategories?: DomainCategory[]
) => {
  const filteredAssets = removeDuplicatedTaggedAssets(assets)
  if (!parentCategories || !parentCategories.length) {
    return filteredAssets
  }

  const categoryAssets = filteredAssets.filter(
    assetsTaggedFor(parentCategories)
  )

  function compareByTagsLength(a, b) {
    return b.tags.length - a.tags.length
  }

  categoryAssets.sort(compareByTagsLength)

  const length = parentCategories.length > 1 ? 3 : 2
  return categoryAssets.filter(a => a.url).slice(0, length)
}
