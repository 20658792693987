import { Currency } from "@sixty-six-north/i18n"
import { CentAmount } from "../cart/CartCalculator"

const currencyDefaults = {
  style: "currency",
  maximumFractionDigits: 2
}
const currencyFormat = {
  ISK: Intl.NumberFormat("de", {
    style: "currency",
    currency: "ISK"
  }),
  DKK: Intl.NumberFormat("de", {
    ...currencyDefaults,
    currency: "DKK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  EUR: Intl.NumberFormat("in", {
    ...currencyDefaults,
    currency: "EUR"
  }),
  GBP: Intl.NumberFormat("en", {
    ...currencyDefaults,
    currency: "GBP"
  }),
  USD: Intl.NumberFormat("en", {
    ...currencyDefaults,
    currency: "USD"
  })
}

export const formatCurrency =
  (currency: Currency) =>
  (value: CentAmount): string => {
    const amount = standardisedCentAmount(currency, value)
    return currencyFormat[currency].format(amount)
  }

export const standardisedCentAmount = (
  currency: Currency,
  value: CentAmount
): number => {
  if (currency === Currency.ISK) {
    return value?.centAmount
  } else {
    return value?.centAmount / 100
  }
}

export const amountByCurrencyForApplePay =
  (currency: Currency) =>
  (value: CentAmount): string => {
    return `${standardisedCentAmount(currency, value)}`
  }
