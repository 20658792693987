import { Cart } from "@commercetools/platform-sdk"
import { Either, isLeft, right, map } from "fp-ts/Either"
import React from "react"
import {
  ValidationError,
  ValidationResult,
  CartValidationResult
} from "./ValidationError"
import { ValidationErrorMonoid, VoidMonoid } from "./ValidationMonoids"

/**
 * Validation strategies act on the current state of the application and
 * decide if a specific validator should be used at this point in time.
 *
 * If a strategy decides that specific validator should be part of the current
 * page's validation process then `getValidator()` should return a `FormValidation`
 *
 * If a strategy decides that specific validator should NOT be part of the current
 * page's validation process then `getValidator()` should return `undefined``
 *
 */
export interface ValidationStrategy {
  getValidator(): FormValidator | undefined
}

export const useValidationStrategy = (
  guard: () => boolean,
  validator: FormValidator
): ValidationStrategy => {
  return {
    getValidator(): FormValidator | undefined {
      return guard() ? validator : undefined
    }
  }
}

export interface CartValidation {
  name: string
  validate(cart: Cart): CartValidationResult
  save(cart: Cart): CartValidationResult
  then(next: CartValidation): CartValidation
}

export abstract class CartValidationBase implements CartValidation {
  public readonly name: string

  protected constructor(name: string) {
    this.name = name
  }

  validate(cart: Cart): CartValidationResult {
    console.info(`Validating ${this.name} at version: ${cart.version}`)
    return cartSuccess(cart)
  }

  save(cart: Cart): CartValidationResult {
    console.info(`Saving ${this.name} at version: ${cart.version}`)
    return cartSuccess(cart)
  }

  then(next: CartValidation): CartValidation {
    return new RecursiveCartValidation(this, next)
  }
}

class RecursiveCartValidation extends CartValidationBase {
  private parent: CartValidation
  private child: CartValidation
  constructor(parent: CartValidation, child: CartValidation) {
    super(`${parent.name}-${child.name}`)
    this.parent = parent
    this.child = child
  }

  validate: (cart: Cart) => CartValidationResult = cart =>
    this.parent
      .validate(cart)
      .then(result =>
        isLeft(result) ? result : this.child.validate(result.right)
      )

  save: (cart: Cart) => CartValidationResult = cart =>
    this.parent
      .save(cart)
      .then(result => (isLeft(result) ? result : this.child.save(result.right)))
}

/**
 * Form validation offers an interface that each "form" on the current
 * page of the checkout journey should implement.
 *
 * Validation consists of two methods:
 *
 * * Validating that the current data is OK
 * * Performing some sort of save operation
 *
 * If any of the validations on the current page fail, the user is
 * blocked from going to the next page
 */
export interface FormValidator {
  name: string
  save: () => ValidationResult
  validate: () => ValidationResult
  refreshCart?: (c: Cart | undefined) => void
}

export interface FormRef {
  formRef: React.MutableRefObject<any>
}

/**
 * Compose multiple form validators into a single
 * form validator
 *
 * @param validators List of individual FormValidator objects
 */
export const composeValidators = (
  ...validators: (FormValidator | undefined)[]
): FormValidator => {
  const reduce = (
    result: Either<ValidationError, void>[]
  ): Either<ValidationError, void> =>
    result.reduce(
      ValidationErrorMonoid(VoidMonoid).concat,
      ValidationErrorMonoid(VoidMonoid).empty
    )

  const doSave = async (): Promise<Either<ValidationError, void>[]> => {
    // Executing serially with "await" as ordering of execution may matter
    // Best example is version numbers of CT objects. We can't fully parallelize
    // everything because we may run into version conflicts if two validators
    // try to save the same object at the same time
    const results: Either<ValidationError, void>[] = []
    for (const validator of validators) {
      if (validator) {
        results.push(await validator.save())
      }
    }
    return results
  }

  const doValidate = async (): Promise<Either<ValidationError, void>[]> => {
    // Executing serially to keep code the same as in `doSave`
    const results: Either<ValidationError, void>[] = []
    for (const validator of validators) {
      if (validator) {
        results.push(await validator.validate())
      }
    }
    return results
  }

  const doRefreshCart = (newCart: Cart | undefined) => {
    for (const validator of validators) {
      if (validator && validator.refreshCart) {
        validator.refreshCart(newCart)
      }
    }
  }

  return {
    name: "ComposedValidator",
    validate: (): ValidationResult => {
      return doValidate().then(reduce)
    },
    refreshCart: (newCart: Cart | undefined): void => {
      doRefreshCart(newCart)
    },
    save: (): ValidationResult => {
      return doSave().then(reduce)
    }
  }
}

/**
 * Compose multiple form validators into a single
 * form validator that exits as soon as any one validator
 * fails.
 *
 * @param validators List of individual FormValidator objects
 */
export const composeValidatorsWithEarlyExit = (
  ...validators: (FormValidator | undefined)[]
): FormValidator => {
  const reduce = (
    result: Either<ValidationError, void>[]
  ): Either<ValidationError, void> =>
    result.reduce(
      ValidationErrorMonoid(VoidMonoid).concat,
      ValidationErrorMonoid(VoidMonoid).empty
    )

  const doSave = async (): Promise<Either<ValidationError, void>[]> => {
    // Executing serially with "await" as ordering of execution may matter
    // Best example is version numbers of CT objects. We can't fully parallelize
    // everything because we may run into version conflicts if two validators
    // try to save the same object at the same time
    const results: Either<ValidationError, void>[] = []
    for (const validator of validators) {
      if (validator) {
        const result = await validator.save()
        results.push(result)

        if (isLeft(result)) {
          return results
        }
      }
    }
    return results
  }

  const doRefreshCart = (newCart: Cart | undefined) => {
    for (const validator of validators) {
      if (validator && validator.refreshCart) {
        validator.refreshCart(newCart)
      }
    }
  }

  const doValidate = async (): Promise<Either<ValidationError, void>[]> => {
    // Executing serially to keep code the same as in `doSave`
    const results: Either<ValidationError, void>[] = []
    for (const validator of validators) {
      if (validator) {
        const result = await validator.validate()
        results.push(result)

        if (isLeft(result)) {
          return results
        }
      }
    }
    return results
  }

  return {
    name: "ComposedValidator",
    validate: (): ValidationResult => {
      return doValidate().then(reduce)
    },
    refreshCart: (newCart: Cart | undefined): void => {
      return doRefreshCart(newCart)
    },
    save: (): ValidationResult => {
      return doSave().then(reduce)
    }
  }
}

export const success = (): ValidationResult => Promise.resolve(right(undefined))

export const cartSuccess = (cart: Cart): CartValidationResult =>
  Promise.resolve(right(cart))
