//jsxhook

import { useUrlSearch } from "./UrlSearchHooks"
import { LocalizedString } from "@commercetools/platform-sdk"
import { BestFor, ProductQuery, SuitableFor } from "../product/ProductDal"

interface Tag {
  name: LocalizedString
  slug: LocalizedString
  filterModification: (pq: ProductQuery) => ProductQuery
}

const everyday: Tag = {
  name: {
    en: "Everyday",
    is: "Hversdags"
  },
  slug: {
    en: "everyday",
    is: "hversdags"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Everyday)
}
const running: Tag = {
  name: {
    en: "Running",
    is: "Hlaup"
  },
  slug: {
    en: "running",
    is: "hlaup"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Running)
}

const skiing: Tag = {
  name: {
    en: "Skiing",
    is: "Skíði"
  },
  slug: {
    en: "skiing",
    is: "skidi"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Skiing)
}
const hiking: Tag = {
  name: {
    en: "Hiking",
    is: "Göngur"
  },
  slug: {
    en: "hiking",
    is: "gongur"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Hiking)
}
const swimming: Tag = {
  name: {
    en: "Swimming",
    is: "Sund"
  },
  slug: {
    en: "swimming",
    is: "sund"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Swimming)
}
const visitingIceland: Tag = {
  name: {
    en: "Visiting Iceland",
    is: "Heimsækja Ísland"
  },
  slug: {
    en: "visiting-iceland",
    is: "heimsaekja-island"
  },
  filterModification: it => it.withBestFor(BestFor.VisitingIceland)
}
const jumpingInPuddles: Tag = {
  name: {
    en: "Jumping in puddles",
    is: "Hoppa í polla"
  },
  slug: {
    en: "jumping-in-puddles",
    is: "hoppa-i-polla"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.JumpingInPuddles)
}
const playingInTheSnow: Tag = {
  name: {
    en: "Playing in the snow",
    is: "Sleðafjör"
  },
  slug: {
    en: "playing-in-snow",
    is: "sledafjor"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.PlayingInSnow)
}
const outdoorActivities: Tag = {
  name: {
    en: "Outdoor Activities",
    is: "Út að leika"
  },
  slug: {
    en: "outdoor-activities",
    is: "ut-ad-leika"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.OutdoorActivities)
}
const sleepingInTheStroller: Tag = {
  name: {
    en: "Sleeping in the stroller",
    is: "Í værum blundi í vagninum"
  },
  slug: {
    en: "sleeping-in-stroller",
    is: "blundar-i-vagninum"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.SleepingInStroller)
}
const newArrivals: Tag = {
  name: {
    en: "New Arrivals",
    is: "Nýjar vörur"
  },
  slug: {
    en: "new-arrivals",
    is: "nyjar-vorur"
  },
  filterModification: it => it.newIn()
}

const outlet: Tag = {
  name: {
    en: "Sale",
    is: "Útsölumarkaður"
  },
  slug: {
    en: "sale",
    is: "utsolumarkadur"
  },
  filterModification: it => it.onSale()
}

const allProducts: Tag = {
  name: {
    en: "All Products",
    is: "Allar vörur"
  },
  slug: {
    en: "all-products",
    is: "allar-vorur"
  },
  filterModification: it => it.removeCategoryFilter()
}

const tags: Tag[] = [
  everyday,
  running,
  skiing,
  hiking,
  swimming,
  visitingIceland,
  jumpingInPuddles,
  playingInTheSnow,
  outdoorActivities,
  sleepingInTheStroller,
  newArrivals,
  // privateSale,
  outlet,
  allProducts
]

export const TAG_FILTER_CONFIG: Record<string, Tag> = tags.reduce(
  (previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue.slug.en]: currentValue,
      [currentValue.slug.is]: currentValue
    }
  },
  {}
)

export function onDynamicCategory<T>(
  categorySlug: string | null,
  callback: (dynamicCategory: string, tag: Tag) => T
) {
  const dynamicCategorySlugs = Object.keys(TAG_FILTER_CONFIG)
  const dynamicCategory = dynamicCategorySlugs.find(slug =>
    categorySlug?.endsWith(slug)
  )
  if (!dynamicCategory) return undefined
  return callback(dynamicCategory, TAG_FILTER_CONFIG[dynamicCategory])
}

export const getCommerceToolsFilterForSlug = (
  slug?: string
): ((plq: ProductQuery) => ProductQuery) => {
  const noModification: (plq: ProductQuery) => ProductQuery = q => q
  return (slug && TAG_FILTER_CONFIG[slug]?.filterModification) || noModification
}
export const useTagFilter = () => useUrlSearch("tag")
