import {
  ProductProjection,
  ProductVariant,
  Store as CTStore,
  Channel
} from "@commercetools/platform-sdk"
import { GlobalProps } from "next/GlobalProps"
import { Store } from "../cart/Stores"
import { CoreProductInformation } from "./models/DetailedProductInformation"
import { DomainCategory } from "./models/DomainCategory"
import { DomainProduct } from "./models/DomainProduct"
import { DomainVariant } from "./models/DomainVariant"

export interface ProductDalI {
  projectionById(id: string, store: Store): Promise<DomainProduct>
}

export interface ProductProjectionsQueryArgs {
  where: ProductProjectionQuery["where"]
  expand: string[]
  limit: number
}

export interface StoreWithDistributionChannels extends CTStore {
  distributionChannels: { typeId: "channel"; id: string; obj?: Channel }[]
}

export interface ProductWithCategory {
  product: CoreProductInformation
  category?: DomainCategory
  subCategory?: DomainCategory
}

export interface PaginatedResult {
  readonly count: number
  readonly total?: number
  readonly offset: number
}

export type VariantFilterFn = (variant: DomainVariant) => boolean

export type PrismicVariantFilterFn = (variant: ProductVariant) => boolean

export interface ListingProductWithFilteredVariantsTransform {
  transform: (p: DomainProduct) => DomainProduct | undefined
}

export interface DetailsProductWithFilteredVariantsTransform {
  transform: ({
    p,
    props,
    category
  }: {
    p: DomainProduct
    props: GlobalProps
    category: DomainCategory | null
  }) => DomainProduct | undefined
}

export interface PrismicProductWithFilteredVariantsTransform {
  transform: (p: ProductProjection) => ProductProjection | undefined
}

export interface AttributeQuery {
  name: string
  value: string
}

export enum OrderBy {
  priceAsc = "priceAsc",
  priceDesc = "priceDesc"
}

export interface ProductProjectionQuery {
  isEmpty: boolean
  where: string
  variantAvailability?: string
}
