import { Product, ProductProjection } from "@commercetools/platform-sdk"
import { Store } from "cart/Stores"
import { filterTruthy } from "../../utils/Filter"
import { DetailsPageProduct } from "./DetailsPageProduct"
import { DetailsPageVariants } from "./DetailsPageVariant"
import { DomainCategories } from "./DomainCategory"
import { SlimListingProduct } from "./SlimListingProduct"
import { VariantType } from "./VariantType"

export type DomainProduct = DetailsPageProduct | SlimListingProduct

export function productToProjection(product: Product): ProductProjection {
  return {
    id: product.id,
    key: product.key,
    version: product.version,
    createdAt: product.createdAt,
    lastModifiedAt: product.lastModifiedAt,
    productType: product.productType,
    ...product.masterData.current
  }
}

export class DomainProducts {
  public static fromProductProjection(
    projection: ProductProjection,
    store?: Store
  ): DomainProduct {
    return {
      id: projection.id,
      key: projection.key,
      name: projection.name,
      description: projection.description,
      slug: projection.slug,
      categories: (projection.categories || [])
        .map(DomainCategories.fromCategoryReference)
        .filter(filterTruthy),
      masterVariant: DetailsPageVariants.fromProductVariant(
        projection.masterVariant,
        store
      ),
      variants: projection.variants.map(variant =>
        DetailsPageVariants.fromProductVariant(variant, store)
      ),
      domainType: VariantType.Detail,
      seoDescription: projection.metaDescription
    }
  }
}
