//jsxhook

import _filter from "lodash/filter"
import _groupBy from "lodash/groupBy"
import _values from "lodash/values"
import { usePreferredLanguage, useStoreContext } from "../i18n/StoreHooks"
import { DomainVariant } from "./models/DomainVariant"
import { ColorwayVariant } from "./models/DetailedProductInformation"
import { SelectColor } from "./product/ColorSelectorNew"
import { SelectSize } from "./product/SizeSelector"
import { ColorwayProxy } from "./ProductPage"
import { VariantProxy } from "./VariantProxy"
import { SKU } from "./VariantProxyI"

export interface HasSize {
  size: string
}

enum Sizes {
  "extra-extra-extra-small" = 1000,
  "extra-extra-small",
  "extra-small",
  "extra-small-small",
  "small",
  "medium",
  "medium-large",
  "large",
  "extra-large",
  "double-extra-large",
  "triple-extra-large",
  "quadruple-extra-large",
  "quintuple-extra-large",
  "one-size",
  "35/36" = 35.5,
  "35-7" = 36,
  "38-0" = 39,
  "41-3" = 42,
  "44-6" = 45,
  "60 L" = 60,
  "90 L" = 90
}

const sizeAsNumeric = (obj: HasSize): number => {
  const { size } = obj
  return isNaN(+size) ? Sizes[size] : Number(size)
}

export const bySizeKeyOrder = (left: HasSize, right: HasSize): number =>
  sizeAsNumeric(left) - sizeAsNumeric(right)

export const useSizes = (
  currentVariant: VariantProxy,
  variants: VariantProxy[],
  setVariant: (v: DomainVariant) => void
) => {
  const language = usePreferredLanguage()
  const currentSize = {
    label: currentVariant.sizeName(language).getOrElse(""),
    isInStock: currentVariant.isInStock
  }

  const allSizes: VariantProxy[] = _filter(variants, it =>
    it.colorName(language).equals(currentVariant.colorName(language))
  )

  return {
    currentSize,
    allSizes: allSizes
      .map(it => ({
        label: it.sizeName(language).getOrElse(""),
        onSelect: () => setVariant(it.variant),
        isInStock: it.isInStock,
        id: it.variant.id,
        size: it.variant.size?.key || ""
      }))
      .sort(bySizeKeyOrder)
  }
}

export const useColorwaySizes = (
  currentSKU: SKU,
  currentColorway: ColorwayProxy,
  setVariant: (v: SKU) => void
): { allSizes: SelectSize[]; currentSize: SelectSize } => {
  const language = usePreferredLanguage()
  const currentVariant = currentColorway.variant(currentSKU)
  const currentSize: SelectSize = {
    label: currentVariant?.size?.label[language] || "",
    isInStock: currentVariant?.availability !== "out-of-stock"
  }

  const allSizes: ColorwayVariant[] = currentColorway.variants

  return {
    currentSize,
    allSizes: allSizes
      .map(it => ({
        label: it.size.label[language] || "",
        onSelect: () => setVariant(it.sku as SKU),
        isInStock: it.availability !== "out-of-stock",
        id: it.sku,
        size: it.size.key || ""
      }))
      .sort(bySizeKeyOrder)
  }
}

export const useColorPicker = (
  currentVariant: VariantProxy,
  variants: VariantProxy[],
  setVariant: (variant: DomainVariant) => void
): { allColors: SelectColor[]; currentColor: SelectColor } => {
  const language = usePreferredLanguage()

  const currentColor: SelectColor = {
    colorName: currentVariant.colorName(language).getOrElse(""),
    colorHex: currentVariant.colorHex().getOrElse("")
  }

  const grouped = _groupBy(variants, it => it.colorHex().getOrElse(""))

  const allColors = _values(grouped).map(v => {
    return {
      colorName: v[0].colorName(language).getOrElse(""),
      colorHex: v[0].colorHex().getOrElse(""),
      onSelect: () =>
        setVariant(v.filter(it => it.isInStock)[0]?.variant || v[0].variant)
    } as SelectColor
  })

  return {
    currentColor,
    allColors
  }
}

export const useStockInformation: () => boolean = () => {
  const storeContext = useStoreContext()
  return (
    storeContext.isShoppingInIceland() ||
    storeContext.isShoppingInUnitedKingdom ||
    storeContext.isShoppingInDenmark
  )
}
