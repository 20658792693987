//jsxhook
import { List, Map, Set } from "immutable"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { usePreferredLanguage } from "../i18n/StoreHooks"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { DomainCategory } from "../product/models/DomainCategory"
import {
  AllCategoriesContext,
  CategoryContext,
  CurrentCategoryContext,
  RootCategoriesContext,
  SubCategoryContext,
  VariantFilterContext
} from "./CategoriesContexts"

type StyleKey = string

export interface GroupedProducts {
  styleKey: string
  styleName: string
  products: CoreProductInformation[]
}

export const useProductShelves: (
  products: CoreProductInformation[]
) => List<GroupedProducts> = (products: CoreProductInformation[]) => {
  const language = usePreferredLanguage()
  const { t } = useTranslation("category")

  let shelves: Map<StyleKey, GroupedProducts> = Map({})

  function put(entry: GroupedProducts) {
    shelves = shelves.update(entry.styleKey, entry, currentValue => {
      return {
        ...currentValue,
        products: Set(currentValue.products.concat(entry.products)).toArray()
      }
    })
  }

  products.forEach(product => {
    const allStyles = product.facets.style || []
    put({
      styleKey: product.facets.style,
      styleName: product.facets.style,
      products: [product]
    })
  })

  return shelves.toList().sortBy(it => it.styleKey)
}

export const useAllCategories: () => DomainCategory[] = () =>
  useContext(AllCategoriesContext)

export const useRootCategories = () => useContext(RootCategoriesContext)
export const useCurrentCategory = () => useContext(CurrentCategoryContext)
export const useCategory = () => useContext(CategoryContext)
export const useSubCategory = () => useContext(SubCategoryContext)
export const useVariantFilter = () => useContext(VariantFilterContext)
