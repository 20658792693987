import _intersection from "lodash/intersection"
import { DomainCategory } from "./models/DomainCategory"
import { SimpleAsset } from "./models/SimpleAsset"

export function assetsTaggedFor(
  parentCategories: DomainCategory[]
): (asset: SimpleAsset) => boolean {
  return (asset: SimpleAsset) => {
    if (parentCategories.length === 0) {
      return true
    }

    const topCategoryKeys: string[] = parentCategories.map(
      topCategory => topCategory.key
    )

    const assetTags = asset.tags.map(tag => tag.toLocaleLowerCase())
    const assetTaggedForTopCategories =
      _intersection(assetTags, topCategoryKeys).length > 0

    return (
      assetTags.length === 0 ||
      (assetTags.length === 1 && ["product", "model"].includes(assetTags[0])) ||
      assetTaggedForTopCategories
    )
  }
}
