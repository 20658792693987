import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PrismicDocument } from "../prismic/PrismicModels"

export interface LayoutStateI {
  menuOpen: boolean
  previousScroll: number
  secondMenuOpen: boolean
  searchOpen: boolean
  transparentMenu: boolean
  tray?: PrismicDocument
}

export const DefaultLayoutState: LayoutStateI = {
  menuOpen: false,
  secondMenuOpen: false,
  previousScroll: 0,
  searchOpen: false,
  transparentMenu: false
}

export const LayoutState = createSlice({
  name: "LayoutState",
  initialState: DefaultLayoutState,
  reducers: {
    openTray(state: LayoutStateI) {
      return {
        ...state,
        menuOpen: true,
        previousScroll: !state.menuOpen ? window.scrollY : state.previousScroll
      }
    },
    closeTray(state: LayoutStateI) {
      return {
        ...state,
        menuOpen: false
      }
    },

    openSecondTray(state: LayoutStateI) {
      return {
        ...state,
        menuOpen: true,
        secondMenuOpen: true
      }
    },
    closeSecondTray(state: LayoutStateI) {
      return {
        ...state,
        secondMenuOpen: false
      }
    },

    closeAllMenus(state: LayoutStateI) {
      return {
        ...state,
        menuOpen: false,
        secondMenuOpen: false
      }
    },
    toggleSearch(state: LayoutStateI) {
      return {
        ...state,
        menuOpen: false,
        previousScroll: 0,
        searchOpen: !state.searchOpen
      }
    },

    setMenuTransparency(
      state: LayoutStateI,
      action: PayloadAction<boolean>
    ): LayoutStateI {
      return {
        ...state,
        transparentMenu: action.payload
      }
    },
    setNavigationDocument(
      state: LayoutStateI,
      action: PayloadAction<PrismicDocument>
    ) {
      return { ...state, tray: action.payload }
    }
  }
})
